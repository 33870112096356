import store from '@/store'

export default {
  inserted: (el, binding, vnode) => {
    if(!binding.value) return
    store.dispatch('getUserInfo').then(user => {
      let myParent = el.parentNode
      if (user.node && !user.node.includes(/* 'admin/' +  */binding.value.replace(/:/g, ':api'))) {
        myParent.removeChild(el)
      }
      if (myParent.childNodes) {
        if (myParent.childNodes.length === 0) {
          return
          myParent.parentNode.removeChild(myParent)
        }
      }
    })
  }
}
